import React, { useEffect, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import {
  ComponentLabelHeader,
  Text14pxMedium,
  Text16pxMedium,
  Text20pxBold,
} from "../../Shared/CommonComponents";
import ProductPricingDetailsCard from "../../Shared/ProductPricingDetailsCard";
import InputTextField from "../../Shared/InputTextField";
import { DollarIcon, ForwardArrow } from "../../../assets/svgs";
import { CheckBoxComponent } from "../../Shared/ToggleComponents";
import TrestleButton from "../../Shared/TrestleButton";
import { CreateCheckoutSession } from "../../Shared/PaymentWallet/Services/PaymentWalletServices";
import { useAppSelector } from "../../../app/hooks";
import { isEmpty } from "lodash";
import ProductCategoryDetailsCardComponent from "../../Shared/ProductCategoryDetailsCard";
import { AppConstants } from "../../../constants/AppConstants";
import { useDispatch } from "react-redux";
import { setWalletFormData } from "../../../app/walletReducer";
import {
  SelfServeFormDataProps,
  SelfServeFormErrorProps,
} from "../../../constants/SelfServeInterfaces";
import {
  validateWalletAmount,
  validateWalletBalanceThreshold,
  validateWalletTargetBalance,
} from "../../../utils/validations";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";

interface SelfServeTabProps {
  setLoading: any;
}

const SelfServeTabComponent = ({ setLoading }: SelfServeTabProps) => {
  const dispatch = useDispatch();
  const walletData = useAppSelector((state) => state.walletReducer.walletData);
  const productsData = [
    {
      "Validation & Verification": [
        { apiName: "Phone Validation API", apiPrice: "0.015" },
        { apiName: "Real Contact API", apiPrice: "0.03" },
      ],
    },
    {
      "Enrichment": [
        { apiName: "Reverse Phone API", apiPrice: "0.07" },
        { apiName: "Reverse Address API", apiPrice: "0.07" },
        { apiName: "Find Person API", apiPrice: "0.07" },
      ],
    },
    {
      "Telephony": [
        { apiName: "Caller Identification API", apiPrice: "0.07" },
        { apiName: "Smart CNAM API", apiPrice: "0.015" },
      ],
    },
  ];

  const [formData, setFormData] = useState<SelfServeFormDataProps>({
    amount: null,
    autoRechargeEnabled: false,
    balanceThreshold: null,
    targetBalance: null,
  });

  const [formError, setFormError] = useState<SelfServeFormErrorProps>({
    amount: "",
    autoRechargeEnabled: "",
    balanceThreshold: "",
    targetBalance: "",
  });

  const handleCheckOutClick = async () => {
    setLoading(true);

    if (!validateField(formData, walletData)) {
      setLoading(false);
      return;
    }
    if (formData.targetBalance && formData.balanceThreshold) {
      setFormData({
        ...formData,
        autoRechargeEnabled: true,
      });
      handleMixPanel(MixPanelEvents.WALLET_AUTOMATIC_REFILL,
      {userEmail: userData?.email,
       walletRefillThreshold: formData.balanceThreshold,
       WalletTargetBalance: formData.targetBalance 
      });
    }
    const payload = {
      amount: formData.amount,
      targetBalance: formData.targetBalance,
      balanceThreshold: formData.balanceThreshold,
      isAutoRechargeEnabled:
        formData.targetBalance && formData.balanceThreshold ? true : false,
    };
    const response = await CreateCheckoutSession(payload);
    const { statusCode = 401, data = {} } = response;
    if (statusCode === 200) {

      window.dataLayer.push({  // Added GMT tag for checkout session start
        event: 'begin_checkout',
        userId: userData?.userId,
        ecommerce: {
          currency: 'USD',
          items: [{
            item_name: 'api credit',
            item_category: formData.balanceThreshold? formData.balanceThreshold : '',
            item_category1: formData.targetBalance? formData.targetBalance : '',
            price: formData.amount
          }]
        }
      });

      window.open(data, "_self");
      handleMixPanel(MixPanelEvents.WALLET_CHECKOUT_SESSION,
      {userEmail: userData?.email,
       amountCheckedOut: formData.amount 
      });
      setLoading(false);
    }
  };

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  useEffect(() => {
    setFormData({
      amount: "",
      autoRechargeEnabled: walletData?.autoRechargeEnabled,
      balanceThreshold: walletData?.balanceThreshold,
      targetBalance: walletData?.targetBalance,
    });
  }, []);

  useEffect(() => {
    if (parseFloat(walletData?.balance as any) < 0) {
      setFormError({
        ...formError,
        amount: `Please input a value greater than $${parseFloat(
          (25 - parseFloat(walletData?.balance as any)) as any
        ).toFixed(2)} to achieve the minimum recommended wallet balance.`,
      });
    }
  }, []);

  useEffect(() => {
    isDisabled();
  }, [formData, formError]);

  const handleChange = (e?: any) => {
    const { name = "", value = "", label = "" } = e?.target;
    const reg = /^[0-9]*$/;
    if (!reg.test(value) || value === " ") {
      return;
    }
    if (name === "targetBalance" || name === "balanceThreshold") {
      setFormError({
        ...formError,
        targetBalance: "",
        balanceThreshold: "",
      });
    } else {
      setFormError({
        ...formError,
        [name]: "",
      });
    }
    setFormData({ ...formData, [name]: value });
  };

  const validateField = (formData: SelfServeFormDataProps, walletData: any) => {
    let isValid = true;
    const temError: SelfServeFormErrorProps = {
      amount: validateWalletAmount(formData, walletData),
      balanceThreshold: validateWalletBalanceThreshold(formData),
      targetBalance: validateWalletTargetBalance(formData),
      autoRechargeEnabled: "",
    };
    if (
      temError.amount !== "" ||
      temError.balanceThreshold !== "" ||
      temError.targetBalance !== ""
    ) {
      setFormError(temError);
      isValid = false;

      handleMixPanel(MixPanelEvents.WALLET_VALIDATION_ERRORS_SELF_SERVE_TAB,
        {userEmail: userData?.email,
         error: temError
        });
    }
    return isValid;
  };

  const isDisabled = () => {
    if (
      isEmpty(formData.amount) ||
      (formData.autoRechargeEnabled === true &&
        (!formData.balanceThreshold || !formData.targetBalance))
    ) {
      return true;
    } else if (
      formError.amount ||
      formError.balanceThreshold ||
      formError.targetBalance
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        minHeight: "100%",
        background: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "50px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "64px",
          alignItems: "center",
          maxWidth: "1250px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            alignItems: "center",
            maxWidth: "800px",
            width: "100%",
          }}
        >
          <ComponentLabelHeader style={{ textAlign: "center" }}>
            All our APIs at a pay-as-you-go rate
          </ComponentLabelHeader>
          <Text16pxMedium c="#163545">
            Standard throughput is 10 QPS. See enterprise plans for custom
            configurations.
          </Text16pxMedium>
        </Box>

        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={6}>
            <Stack rowGap={3} sx={{ padding: "48px" }}>
              <Text20pxBold c="#163545">Our API costs</Text20pxBold>
              {productsData?.map((item) => {
                const data = Object.entries(item);
                return (
                  <ProductCategoryDetailsCardComponent
                    productCategoryName={data[0][0]}
                    productCategoryAPIList={data[0][1]}
                  />
                );
              })}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Stack
              rowGap={4}
              sx={{
                padding: "48px",
                border: "1px solid #555555",
                height: "100%",
              }}
            >
              <Text20pxBold c="#163545">Add funds to your wallet</Text20pxBold>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <InputTextField
                  label="Initial Wallet Value"
                  placeholder="Enter a value between $25–250"
                  name="amount"
                  type={"text"}
                  onChange={handleChange}
                  value={formData}
                  error={formError}
                  searchable={true}
                  startIcon={DollarIcon}
                  height="48px"
                  showErrorInBottom={true}
                  startAdornmentStyle="!ml-0 !max-h-[100%] !w-[48px] !bg-[#E5E5E5] !h-[100%] justify-center items-center"
                  className="!px-0"
                />
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <CheckBoxComponent
                    value={formData.autoRechargeEnabled}
                    toggleCheck={() => {
                      setFormData({
                        ...formData,
                        targetBalance: "",
                        balanceThreshold: "",
                        autoRechargeEnabled: !formData.autoRechargeEnabled,
                      });
                      setFormError({
                        ...formError,
                        autoRechargeEnabled: "",
                        balanceThreshold: "",
                        targetBalance: "",
                      });
                    }}
                    disabled={false}
                    className="w-[24px] h-[24px]"
                  />
                  <Text14pxMedium c="#163545">
                    Automatically refill wallet when it goes below a threshold.
                  </Text14pxMedium>
                </Box>
                {/* {formData?.autoRechargeEnabled && (
                  <> */}
                <InputTextField
                  label="Wallet Refill Threshold"
                  name="balanceThreshold"
                  onChange={handleChange}
                  placeholder="Enter a value between $10–250"
                  type={"text"}
                  value={formData}
                  error={formError}
                  searchable={true}
                  startIcon={DollarIcon}
                  height="48px"
                  showErrorInBottom={true}
                  startAdornmentStyle="!ml-0 !max-h-[100%] !w-[48px] !bg-[#E5E5E5] !h-[100%] justify-center items-center"
                  className="!px-0"
                />
                <InputTextField
                  label="Refill Wallet To"
                  name="targetBalance"
                  type={"text"}
                  onChange={handleChange}
                  placeholder="Enter a value between $25–250"
                  value={formData}
                  error={formError}
                  searchable={true}
                  startIcon={DollarIcon}
                  height="48px"
                  showErrorInBottom={true}
                  startAdornmentStyle="!ml-0 !max-h-[100%] !w-[48px] !bg-[#E5E5E5] !h-[100%] justify-center items-center"
                  className="!px-0"
                />
                {/* </>
                )} */}
                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                  <TrestleButton
                    onClick={() => {
                      handleCheckOutClick();
                      handleMixPanel(MixPanelEvents.WALLET_CHECKOUT_BUTTON,
                      {userEmail: userData?.email,
                       initalValueToAdd: formData.amount,
                       walletRefillThreshold: formData.balanceThreshold,
                       walletTargetBalance: formData.targetBalance 
                      });
                    }}
                    label="Checkout"
                    type="primary"
                    className="!h-[60px]"
                    disabled={isDisabled() || !walletData.topUpEligible}
                  />
                  {!walletData.topUpEligible && (
                      <Text14pxMedium style={{ color: '#b23352'}}>
                        Maximum Wallet Charges processed this month, please reach out to support@trestleiq.com
                      </Text14pxMedium>
                    )}
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SelfServeTabComponent;
